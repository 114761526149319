'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitsManager

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitsManager', [
    'MundoDefaultManager'
    'TenantManager'
    'UnitContactsManager'
    'UnitDevicesManager'
    'UnitsStatusManager'
    'AssetManager'
    'ContactManager'
    'UnitContactType'
    'DeviceTypeManager'
    'DeviceManager'
    'TemplateUtils'
    'moment'
    'RestUtils'
    '$mdDialog'
    '$log'
    '$translate'
    'Restangular'
    '$state'
    '$timeout'
    '$rootScope'
    (
      MundoDefaultManager
      TenantManager
      UnitContactsManager
      UnitDevicesManager
      UnitsStatusManager
      AssetManager
      ContactManager
      UnitContactType
      DeviceTypeManager
      DeviceManager
      TemplateUtils
      moment
      RestUtils
      $mdDialog
      $log
      $translate
      Restangular
      $state
      $timeout
      $rootScope
    )->
      UnitsManagerBase = new MundoDefaultManager()
      UnitsManagerBase.setUrl('units')
      UnitsManagerBase.setAutoTenant()
      UnitsManagerBase.setNewObject(['label', 'comments', 'startDate', 'endDate', 'unitStatus', 'asset'])
      UnitsManagerBase.setUpdateObject(['label', 'comments', 'startDate', 'endDate', 'unitStatus', 'asset'])
      UnitsManagerBase.editPermission = 'manage all MundoMosaUnitBundle:Unit entities'

  #   base
      UnitsManagerBase.new = (data) ->
        if data.startDate
          data.startDate = moment(data.startDate).format('YYYY-MM-DD')

        if data.endDate
          data.endDate = moment(data.endDate).format('YYYY-MM-DD')

        if data.asset? and data.asset.id?
          data.asset = data.asset.id

        @transport.all(@getUrl()).post(_.pick(data, @getNewObject()), {}, autoTenant: @conf.autoTenant)

      UnitsManagerBase.update = (id, data) ->
        if data.startDate
          data.startDate = moment(data.startDate).format('YYYY-MM-DD')

        if data.endDate
          data.endDate = moment(data.endDate).format('YYYY-MM-DD')

        if data.asset? and data.asset.id?
          data.asset = data.asset.id

        @transport.all(@getUrl()).one(id).customPUT(_.pick(data, @getUpdateObject()))

      UnitsManagerBase.getNewForm = ->
        startDate = new Date()
        # endDate = new Date()
        endDate = null

        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: $translate.instant('datatable.label.label')
            placeholder: $translate.instant('datatable.label.label')
            required: true
        ,
          key: 'unitStatus'
          name: 'unitStatus'
          type: 'select'
          templateOptions:
            label: $translate.instant('datatable.label.unitStatus')
            placeholder: $translate.instant('datatable.label.unitStatus')
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitsStatusManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'startDate'
          name: 'startDate'
          type: 'datepicker'
          defaultValue: startDate
          templateOptions:
            label: $translate.instant('datatable.label.startDate')
            placeholder: $translate.instant('datatable.label.startDate')
            required: true
        ,
          key: 'endDate'
          name: 'endDate'
          type: 'datepicker'
          defaultValue: endDate
          templateOptions:
            label: $translate.instant('datatable.label.endDate')
            placeholder: $translate.instant('datatable.label.endDate')
            required: false
        ,
          key: 'asset'
          name: 'asset'
          type: 'autocomplete'
          templateOptions:
            placeholder: $translate.instant('datatable.label.asset')
            required: true
            displayValue: 'label'
            options: AssetManager.getFullList({'sort' : 'label,ASC'}).$object
            valueProp: "id"
        ,
          key: 'comments'
          name: 'comments'
          type: 'textarea'
          templateOptions:
            label: $translate.instant('datatable.label.comments')
            placeholder: $translate.instant('datatable.label.comments')
            required: false
            rows: 5
        # ,
        #   key: 'marker'
        #   name: 'marker'
        #   type: 'select'
        #   defaultValue : null
        #   templateOptions:
        #     label: 'Marker'
        #     placeholder: 'marker'
        #     required: false
        #     labelProp: "code"
        #     valueProp: "code"
        #     options: UnitMarkersManager.getFullList().$object
        ]

      UnitsManagerBase.getEditForm = (data) ->
        startDate = data.startDate
        endDate = data.endDate

        if startDate
          startDate = moment(startDate).toDate()

        if endDate
          endDate = moment(endDate).toDate()

        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: $translate.instant('datatable.label.label')
            placeholder: $translate.instant('datatable.label.label')
            required: true
        ,
          key: 'unitStatus'
          name: 'unitStatus'
          type: 'select'
          defaultValue: if data.unitStatus then data.unitStatus.id else ''
          templateOptions:
            label: $translate.instant('datatable.label.unitStatus')
            placeholder: $translate.instant('datatable.label.unitStatus')
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitsStatusManager.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'startDate'
          name: 'startDate'
          type: 'datepicker'
          defaultValue: startDate
          templateOptions:
            label: $translate.instant('datatable.label.startDate')
            placeholder: $translate.instant('datatable.label.startDate')
            required: true
        ,
          key: 'endDate'
          name: 'endDate'
          type: 'datepicker'
          defaultValue: endDate
          templateOptions:
            label: $translate.instant('datatable.label.endDate')
            placeholder: $translate.instant('datatable.label.endDate')
            required: false
        ,
          key: 'asset'
          name: 'asset'
          type: 'autocomplete'
          defaultValue: data.asset
          templateOptions:
            placeholder: $translate.instant('datatable.label.asset')
            required: true
            displayValue: 'label'
            options: AssetManager.getFullList({'sort' : 'label,ASC'}).$object
            valueProp: "id"
        ,
          key: 'comments'
          name: 'comments'
          type: 'textarea'
          templateOptions:
            label: $translate.instant('datatable.label.comments')
            placeholder: $translate.instant('datatable.label.comments')
            required: false
            rows: 5
        # ,
        #   key: 'marker'
        #   name: 'marker'
        #   type: 'select'
        #   defaultValue : data.marker
        #   templateOptions:
        #     label: 'Marker'
        #     placeholder: 'marker'
        #     required: false
        #     labelProp: "code"
        #     valueProp: "code"
        #     options: UnitMarkersManager.getFullList().$object
        ]

      UnitsManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/units/views/units-detail.tpl.html'

      UnitsManagerBase.getDetailModalTemplateUrl = ->
        'mundo-admin/units/views/units-detail-modal.tpl.html'

      UnitsManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
          sort: 'label'
          searchable: true
        ,
          key: 'startDate'
          title: 'datatable.label.startDate'
          sort: 'startDate'
          type: 'date'
        ,
          key: 'endDate'
          title: 'datatable.label.endDate'
          sort: 'endDate'
          type: 'date'
        ,
          key: 'unitStatus.code'
          title: 'datatable.label.unitStatus'
          sort: 'unitStatus.label'
          searchable: true
         ,
           key: 'asset.label'
           title: 'datatable.label.asset'
           sort: 'asset.label'
           searchable: false
           hideInList: true
        # ,
        #   key: 'tenant.label'
        #   title: 'datatable.label.tenant'
        #   hideInList: true
        ,
          key: 'comments'
          title: 'datatable.label.comments'
          sort: 'comments'
          hideInList: true
        ]

      getSearchFields = ->
        searchItems = []
        angular.forEach UnitsManagerBase.getListSchema(), (schemaItem) ->
          if schemaItem.searchable
            searchItems.push schemaItem.key
        $log.debug 'searchFields:', searchItems
        searchItems

      UnitsManagerBase.setSearchFields(getSearchFields())

      #   devices
      UnitsManagerBase.setObject('unitDevice', [
        'device'
        'deviceTypeProfile'
        'managed'
        'installed'
        'deviceTypeProfileSwitchingAllowed'
        'parameters'
      ])

      UnitsManagerBase.getDevices = (id) ->
        RestUtils.getFullList @transport.one(@conf.url, id).all('devices')

      UnitsManagerBase.addDevice = (id, data) ->
        @transport.one(@conf.url, id).all('devices').post(_.pick(data, @getObject('unitDevice')))

      UnitsManagerBase.removeDevice = (unitId, contactId) ->
        @transport.one(@conf.url, unitId).one('devices', contactId).remove()

      UnitsManagerBase.getDevicesForm = (items) ->

        itemIds = []
        for item in items then itemIds.push item.device.id

        devices = []
        DeviceManager.getFullList({
            'sort': 'label,ASC',
            'filter[]': [
              'deviceStatus.code,eq+ACTIVE',
            ]
        }).then (data) ->
          for device in data
            if device.id not in itemIds
              devices.push device

        [
          key: 'device'
          name: 'device'
          type: 'autocomplete'
          templateOptions:
            label: $translate.instant('app.admin.devices.device')
            placeholder: $translate.instant('app.admin.devices.device')
            required: true
            displayValue: "label"
            options: devices
        ,
          key: 'deviceTypeProfile'
          name: 'deviceTypeProfile'
          type: 'select'
          templateOptions:
            label: $translate.instant('app.admin.devices.device-type-profile')
            placeholder: $translate.instant('app.admin.devices.device-type-profile')
            required: true
            labelProp: "label"
            valueProp: "code"
            options: []
          data:{
            devices: devices
          }
          hideExpression: '!model.device'
          controller: [
            '$scope'
            'DeviceTypeManager'
            ($scope, DeviceTypeManager)->
              $scope.$watch 'model.device', (device, oldValue, theScope) ->
                # device = _.findWhere $scope.options.data.devices, {id: device.id}
                if device
                  $scope.to.options = DeviceTypeManager.getProfilesByType(device.deviceType)
                else
                  $scope.to.options = []
          ]
        ,
          key: 'managed'
          name: 'managed'
          type: 'checkbox'
          hideExpression: '!model.device'
          templateOptions:
            label: $translate.instant('app.managed')
        ,
          key: 'installed'
          name: 'installed'
          type: 'checkbox'
          hideExpression: '!model.device'
          templateOptions:
            label: $translate.instant('app.installation')
        ,
          key: 'deviceTypeProfileSwitchingAllowed'
          name: 'deviceTypeProfileSwitchingAllowed'
          type: 'checkbox'
          hideExpression: '!model.device'
          templateOptions:
            label: $translate.instant('app.units.device-type-profile-switching-allowed')
        ,
          key: 'allowedDeviceTypeProfiles'
          name: 'allowedDeviceTypeProfiles'
          type: 'modelchips'
          hideExpression: '!model.device || !model.deviceTypeProfileSwitchingAllowed'
          templateOptions:
            label: $translate.instant('app.units.allowed-device-type-profiles')
            multiple: true,
            placeholder: 'allowedDeviceTypeProfiles'
            required: false
            labelProp: "label"
            valueProp: "code"
            options: []
          controller: [
            '$scope'
            'DeviceTypeManager'
            ($scope, DeviceTypeManager)->
              $scope.$watch 'model.device', (device, oldValue, theScope) ->
                # device = _.findWhere $scope.options.data.devices, {id: device.id}
                if device
                  $scope.to.options = DeviceTypeManager.getProfilesByType(device.deviceType)
                else
                  $scope.to.options = []
          ]
        ]

      assignDevice =
        [
          icon: 'devices'
          text: 'datatable.tooltips.unitDevice'
          permission: UnitsManagerBase.editPermission
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/units/views/unit-devices.modal.tpl.html'
              controller: 'UnitDevicesCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      UnitsManagerBase.addExtraAction(assignDevice)


  #   contacts
      UnitsManagerBase.setObject('unitContact', ['unitContactType', 'contact'])

      UnitsManagerBase.getContacts = (id) ->
        RestUtils.getFullList @transport.one(@conf.url, id).all('contacts')

      UnitsManagerBase.addContact = (id, data) ->
        if data.contact?
          data.contact = data.contact.id
        @transport.one(@conf.url, id).all('contacts').post(_.pick(data, @getObject('unitContact')))

      UnitsManagerBase.removeContact = (unitId, contactId) ->
        @transport.one(@conf.url, unitId).one('contacts', contactId).remove()

      UnitsManagerBase.getContactsForm = ->
        [
          key: 'unitContactType'
          name: 'unitContactType'
          type: 'select'
          templateOptions:
            label: 'Contact Type'
            placeholder: 'Contact Type'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: UnitContactType.getFullList({'sort' : 'label,ASC'}).$object
        ,
          key: 'contact'
          name: 'contact'
          type: 'autocomplete'
          templateOptions:
            label: 'Contact'
            placeholder: 'Contact'
            required: true
            displayValue: "label"
            options: ContactManager.getFullList({'sort' : 'label,ASC'}).$object
        ]

      assignContact =
        [
          icon: 'perm_identity'
          text: 'datatable.tooltips.unitContact'
          permission: UnitsManagerBase.editPermission
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/units/views/unit-contacts.modal.tpl.html'
              controller: 'UnitContactsCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      UnitsManagerBase.addExtraAction(assignContact)

      deviceParameters =
        [
          icon: 'perm_device_information'
          text: 'datatable.tooltips.unitDeviceParameters'
          permission: UnitsManagerBase.editPermission
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/units/views/unit-device-parameters.modal.tpl.html'
              controller: 'UnitDevicesParametersCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      UnitsManagerBase.addExtraAction(deviceParameters)

      UnitsManagerBase.addDetailEditAction('editContact',
          [
            title: "app.admin.actions.contacts.editContact"
            entityManager: UnitContactsManager
          ]
      )

      UnitsManagerBase.addDetailEditAction('editDevice',
          [
            title: "app.admin.actions.devices.editDevice"
            entityManager: UnitDevicesManager
          ]
      )

      UnitsManagerBase.saveDeviceParameters = (unitId, deviceId, parameters) ->
        Restangular
          .service('services/units')
          .one(unitId)
          .one('devices')
          .one(deviceId)
          .one('parameters')
          .customPUT(parameters)

      UnitsManagerBase.setDeviceProfile = (unitId, deviceId, profile) ->
        Restangular
          .service('services/units')
          .one(unitId)
          .one('devices')
          .one(deviceId)
          .one('profile')
          .customPUT
            deviceTypeProfile: profile

      UnitsManagerBase.getOne = UnitsManagerBase.one

      UnitsManagerBase.one = (id) ->
        UnitsManagerBase.getOne(id)
          .then (result) ->
            # add search functionality
            for device in result.unitDevices
              device.search = (label) ->
                $state.go('devices.overview').then(->
                  $timeout(->
                    $rootScope.$broadcast('searchFor',
                      {term: label, to: 'app.admin.pageTitles.devices.devices'})
                  ,100)
                )
            for contact in result.unitContacts
              contact.search = (label) ->
                $state.go('contacts.overview').then(->
                  $timeout(->
                    $rootScope.$broadcast('searchFor',
                      {term: label, to: 'app.admin.pageTitles.contacts.contacts'})
                  ,100)
                )

            result

      UnitsManagerBase
  ]
